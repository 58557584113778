import React from "react";
import "../styles/components/FloatNav.scss";
import MenuIcon from "../assets/images/official/books.png";

export default function FloatNav({ setDrawerStatus }) {
	return (
		<>
			<a className="floatnav" onClick={() => setDrawerStatus()}>
				<img src={MenuIcon} />
			</a>
		</>
	);
}
