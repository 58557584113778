import React from "react";
import { Col, Row } from "react-bootstrap";
import "../styles/pages/Courses.scss";
import { useTranslation } from "react-i18next";
import Course2Img1 from "../assets/images/official/courses/Talent web-15.png";
import Course2Img2 from "../assets/images/official/courses/Talent web-16.png";
import Course2Img3 from "../assets/images/official/courses/Talent web-17.png";
import Course3Img1 from "../assets/images/official/courses/Talent web-18.png";
import Course3Img2 from "../assets/images/official/courses/Talent web-19.png";
import Course4Img1 from "../assets/images/official/courses/Talent web-20.png";
import Course4Img2 from "../assets/images/official/courses/Talent web-21.png";
import Course5Img1 from "../assets/images/official/courses/Talent web-22.png";
import Course5Img2 from "../assets/images/official/courses/Talent web-23.png";
import Course6Img1 from "../assets/images/official/courses/Talent web-24.png";
import Course7Img1 from "../assets/images/official/courses/Talent web-25.png";
import Course8Img1 from "../assets/images/official/courses/Talent web-26.png";
import Course8Img2 from "../assets/images/official/courses/Talent web-27.png";
import Course8Img3 from "../assets/images/official/courses/Talent web-28.png";
import "../styles/pages/Responsive/Courses.scss";

export default function Courses() {
	const { t } = useTranslation();

	return (
		<>
			<div className="talents-courses">
				<section className="course-one">
					<div className="one-data">
						<h1 className="one-data-title">{t("courses.programs")}</h1>
						<div className="one-data-hr"></div>
						<p className="one-data-subtitle">{t("courses.dev_and_edu")}</p>
						<a className="one-data-link">{t("courses.register_now")}</a>
					</div>
				</section>
				<section className="course-two">
					<div className="course-context">
						<div className="coursetype">
							<h3 className="coursetype-title">{t("courses.primary_tuition")}</h3>
							<p className="coursetype-descp">{t("courses.primary_tuition_desc")}</p>
							<img className="coursetype-img1" src={Course2Img2}></img>
							<img className="coursetype-img2" src={Course2Img3}></img>
							<a className="coursetype-link" href="#">
								{t("courses.learn_more")}
							</a>
						</div>
						<img className="coursetype-external-img" src={Course2Img1}></img>
					</div>
				</section>
				<section className="course-three">
					<div className="course-context">
						<div className="coursetype">
							<h3 className="coursetype-title">{t("courses.ucmas")}</h3>
							<p className="coursetype-descp">{t("courses.ucmas_desc")}</p>
							<img className="coursetype-img1" src={Course3Img1}></img>
							<a className="coursetype-link" href="#">
								{t("courses.learn_more")}
							</a>
						</div>
						<img className="coursetype-external-img" src={Course3Img2}></img>
					</div>
				</section>
				<section className="course-four">
					<div className="course-context">
						<Row>
							<Col md={6} lg={6} className="course-four-col">
								<img className="coursetype-img2" src={Course4Img1}></img>
							</Col>
							<Col md={6} lg={6} className="course-four-col right">
								<div className="coursetype">
									<h3 className="coursetype-title">{t("courses.robot")}</h3>
									<p className="coursetype-descp">{t("courses.robot_desc")}</p>
									<img className="coursetype-img1" src={Course4Img2}></img>
									<a className="coursetype-link" href="#">
										{t("courses.learn_more")}
									</a>
								</div>
							</Col>
						</Row>
					</div>
				</section>
				<section className="course-five">
					<div className="course-context">
						<Row>
							<Col lg={7} className="course-five-col">
								<div className="coursetype">
									<h3 className="coursetype-title">{t("courses.chinese_reading")}</h3>
									<p className="coursetype-descp">{t("courses.chinese_reading_desc")}</p>
									<img className="coursetype-img1" src={Course5Img1}></img>
									<a className="coursetype-link" href="#">
										{t("courses.learn_more")}
									</a>
								</div>
							</Col>
							<Col lg={5} className="course-five-col right">
								<img className="coursetype-img2" src={Course5Img2}></img>
							</Col>
						</Row>
					</div>
				</section>
				<section className="course-six">
					<div className="course-context">
						<div className="coursetype">
							<h3 className="coursetype-title">{t("courses.funart")}</h3>
							<p className="coursetype-descp">{t("courses.funart_desc")}</p>
							<img className="coursetype-img1" src={Course6Img1}></img>
							<a className="coursetype-link" href="#">
								{t("courses.learn_more")}
							</a>
						</div>
					</div>
				</section>
				<section className="course-seven">
					<div className="course-context">
						<div className="coursetype">
							<h3 className="coursetype-title">{t("courses.speech_and_drama")}</h3>
							<p className="coursetype-descp">{t("courses.speech_and_drama_desc")}</p>
							<img className="coursetype-img1" src={Course7Img1}></img>
							<a className="coursetype-link" href="#">
								{t("courses.learn_more")}
							</a>
						</div>
					</div>
				</section>
				<section className="course-eight">
					<div className="course-context">
						<div className="coursetype">
							<h3 className="coursetype-title">{t("courses.funart")}</h3>
							<p className="coursetype-descp">{t("courses.funart_desc")}</p>
							<Row className="eight-row">
								<Col className="eight-col" lg={4} md={6} sm={12} xs={12}>
									<div className="eight-img-bg">
										<img className="eight-col-img" src={Course8Img1} />
									</div>
									<p className="eight-col-title">{t("courses.educare")}</p>
									<a className="coursetype-link" href="#">
										{t("courses.learn_more")}
									</a>
								</Col>
								<Col className="eight-col" lg={4} md={6} sm={12} xs={12}>
									<div className="eight-img-bg">
										<img className="eight-col-img" src={Course8Img2} />
									</div>
									<p className="eight-col-title">{t("courses.dance")}</p>
									<a className="coursetype-link" href="#">
										{t("courses.learn_more")}
									</a>
								</Col>
								<Col className="eight-col" lg={4} md={6} sm={12} xs={12}>
									<div className="eight-img-bg">
										<img className="eight-col-img" src={Course8Img3} />
									</div>
									<p className="eight-col-title">{t("courses.martial_art")}</p>
									<a className="coursetype-link" href="#">
										{t("courses.learn_more")}
									</a>
								</Col>
							</Row>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
