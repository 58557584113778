import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locale/en.js";
import zh from "./locale/zh.js";

const resources = {
	zh,
	en,
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: JSON.parse(localStorage.getItem("locale")),
		fallbackLng: "en",
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
