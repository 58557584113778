import React from "react";
import { Col, Row } from "react-bootstrap";
import "../styles/components/Footer.scss";
import FooterLogo from "../assets/images/official/common/logo-white.png";
import { useTranslation } from "react-i18next";
import "../styles/components/Responsive/Footer.scss";

const Footer = () => {
	const { t } = useTranslation();

	const menuLinks = [
		{
			name: t("footer.talent_child"),
			link: "#",
		},
		{
			name: t("footer.about"),
			link: "#",
		},
		{
			name: t("footer.event"),
			link: "#",
		},
		{
			name: t("footer.courses"),
			link: "#",
		},
		{
			name: t("footer.gallery"),
			link: "#",
		},
	];

	const learnLinks = [
		{
			name: t("footer.howitworks"),
			link: "#",
		},
		{
			name: t("footer.achievement"),
			link: "#",
		},
		{
			name: t("footer.testimonial"),
			link: "#",
		},
		{
			name: t("footer.faqs"),
			link: "#",
		},
	];

	return (
		<>
			<div className="footer">
				<div className="footer-info">
					<a href="#" className="footer-logo">
						<img src={FooterLogo} />
					</a>
					<div className="footer-link-list">
						<Row className="footer-row">
							<Col lg={4} md={4} sm={6} xs={6} className="footer-col linkss">
								<p className="footer-title">{t("footer.menu")}</p>
								<div className="footer-links">
									{menuLinks.map((i, k) => {
										return (
											<a className="footer-link" href={i.link} key={k}>
												<span>{i.name}</span>
											</a>
										);
									})}
								</div>
							</Col>
							<Col lg={5} md={4} sm={6} xs={6} className="footer-col">
								<p className="footer-title">{t("footer.needhelp")}?</p>
								<div className="footer-contact">
									<p className="footer-contact-label">{t("footer.emailus")}</p>
									<a href="#" className="footer-contact-sent">
										<span>info@talents.com</span>
									</a>
								</div>
								<div className="footer-contact">
									<p className="footer-contact-label">{t("footer.callus")}</p>
									<a href="#" className="footer-contact-sent">
										<span>012-345678</span>
									</a>
								</div>
							</Col>
							<Col lg={3} md={4} sm={6} xs={6} className="footer-col linkss">
								<p className="footer-title">{t("footer.learn")}</p>
								<div className="footer-links">
									{learnLinks.map((i, k) => {
										return (
											<a className="footer-link" href={i.link} key={k}>
												<span>{i.name}</span>
											</a>
										);
									})}
								</div>
							</Col>
						</Row>
					</div>
				</div>
				<div className="footer-hr"></div>
			</div>
		</>
	);
};

export default Footer;
