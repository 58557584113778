import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Website } from "../Routes";
import NavBar from "../components/NavBar";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import FloatNav from "../components/FloatNav";
import MobileDrawer from "../components/MobileDrawer";
import "../styles/General.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useMediaQuery } from "react-responsive";

export default function Main() {
	const [drawerStatus, setDrawerStatus] = React.useState(false);
	const isSmallOrMediumScreen = useMediaQuery({ query: "(max-width: 992px)" });
	const mapRoutes = (Website) => {
		return Website.map((i, k) => {
			return <Route exact path={i.path} component={() => <i.component />} key={k} />;
		});
	};

	return (
		<>
			<NavBar />
			<Switch>
				{mapRoutes(Website)}
				<Redirect from="*" to="/" />
			</Switch>
			<Contact />
			{isSmallOrMediumScreen ? (
				<>
					<FloatNav setDrawerStatus={() => setDrawerStatus(!drawerStatus)} />
					<MobileDrawer status={drawerStatus} setDrawerStatus={() => setDrawerStatus(!drawerStatus)} />
				</>
			) : null}
			<Footer />
		</>
	);
}
