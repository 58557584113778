import React from "react";
import { Image, Col, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import StarRatings from "react-star-ratings";
import "../styles/pages/Home.scss";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Carousel as PrimeCarousel } from "primereact/carousel";
import { useTranslation } from "react-i18next";
import "../styles/pages/Responsive/Home.scss";

import logo from "../assets/images/official/common/Talent web-07.png";
import facebookLogo from "../assets/images/official/home/facebook-logo.png";
import Home2 from "../assets/images/official/home/Talent web-53.png";
import PlayLogo from "../assets/images/official/home/Talent web-39.png";
import Virtual1 from "../assets/images/official/home/Talent web-54.png";
import Virtual2 from "../assets/images/official/home/Talent web-55.png";
import Virtual3 from "../assets/images/official/home/Talent web-56.png";
import Virtual4 from "../assets/images/official/home/Talent web-57.png";
import Course1 from "../assets/images/official/home/course1.png";
import Course2 from "../assets/images/official/home/course2.png";
import Course3 from "../assets/images/official/home/course3.png";
import Course4 from "../assets/images/official/home/course4.png";
import Course5 from "../assets/images/official/home/course5.png";
import HomeBackground from "../assets/images/official/home/Talent web-08.png";
import ArrowBlue from "../assets/images/official/home/Talent web-58.png";
import Forward from "../assets/images/official/home/forward.png";
import TestimonialProfile from "../assets/images/official/home/testimonial-sample.png";

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 3,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

const responsiveOptions = [
	{
		breakpoint: "1024px",
		numVisible: 3,
		numScroll: 3,
	},
	{
		breakpoint: "950px",
		numVisible: 2,
		numScroll: 2,
	},
	{
		breakpoint: "800px",
		numVisible: 2,
		numScroll: 2,
	},
	{
		breakpoint: "600px",
		numVisible: 1,
		numScroll: 1,
	},
	{
		breakpoint: "480px",
		numVisible: 1,
		numScroll: 1,
	},
];

const coursesList = [
	{
		img: Course1,
		title: "Mental Arithmetic (UCMAS)",
		descp: "UCMAS is a unique and scientifically-proven mental development programme designed for children to develop their latent mental power at a very early age when brain development is at its peak. Try it and watch your child grow into a math genius!",
	},
	{
		img: Course2,
		title: "FUN Arts",
		descp: "FUN Arts is an arts and crafts programme that is interesting for children as our art teacher uses different sorts of mediums to teach children to create meaningful artworks. Come visit us at our centre and see for yourself! We proudly present the artworks there.",
	},
	{
		img: Course3,
		title: "Chinese Reading Programme",
		descp: "One of our most popular programmes for parents who wish for their children to have a good command of the Chinese language. We use flashcards because they help students to engage in active recall to remember a term, concept, or process without context clues. This is why students who join our CRP remembers a lot of Chinese characters effectively.",
	},
	{
		img: Course4,
		title: "Speech and Drama",
		descp: "A powerful way for children to learn the necessary building blocks for a life of effective communication. It empowers children and enables them to develop self-confidence, a creative imagination, and maturity in interpersonal skills. Watch your child blossom into a star after joining this!",
	},
	{
		img: Course5,
		title: "Primary School Tuitions",
		descp: "Your child will be on top of his or her schoolwork and never fall behind in class with this programme. Our teachers will guide them in completing their school homework, while simultaneously helping them recall and apply what’s taught in school. This will also help instil discipline and time management skills in your child.",
	},
];

const carouselKnowledge = [
	{
		img: Virtual1,
	},
	{
		img: Virtual2,
	},
	{
		img: Virtual3,
	},
	{
		img: Virtual4,
	},
];

const carouselTestimonial = [
	{
		img: TestimonialProfile,
		name: "Samuel",
		star: 5,
		review: "My child has been sent Talents for tuition and school homework since the first grade. She is now in the sixth grade. I don’t have to worry about schoolwork and homework, and the test scores have always been in the top 10. Don't worry about handing over your children to Talents!",
	},
	{
		img: TestimonialProfile,
		name: "Samuel",
		star: 5,
		review: "I signed up for the mental arithmetic class here for my children after being introduced by a friend. The teacher here is very dedicated to teaching. My child's math and mental arithmetic have been improving. Now he can calculate the total faster than the computer when he is eating and checking out. Even the restaurant owner praises him for his quick mind.",
	},
	{
		img: TestimonialProfile,
		name: "Samuel",
		star: 5,
		review: "Because my child did not have a good foundation in kindergarten, suku kata and the vocabulary we didn’t know much, my husband and I were busy working again. Fortunately, the teacher at Talents taught my child very carefully. Now he can handle the primary school curriculum.",
	},
	{
		img: TestimonialProfile,
		name: "Samuel",
		star: 5,
		review: "My child likes to paint, so I sent him here to learn. Unexpectedly, the more he learns, the more he likes it. He told me that not only watercolor and crayons are used here, but the teacher also prepares various handicrafts for them. Every time I see my child with a smile, bringing home his artwork, I knew Talents was the right choice.",
	},
];

const testimonialCard = (carouselTestimonial) => {
	return (
		<div className="testimonial-item">
			<StarRatings
				className="testimonial-stars"
				rating={carouselTestimonial.star}
				starDimension="15px"
				starRatedColor="rgb(255, 117, 18)"
				numberOfStars={5}
			/>
			<blockquote className="testimonial-blockquote">{carouselTestimonial.review}</blockquote>
			<div className="testimonial-profile">
				<img className="testimonial-avatar" src={carouselTestimonial.img} />
				<p className="testimonial-name">{carouselTestimonial.name}</p>
			</div>
		</div>
	);
};

export default function Home(props) {
	const { t } = useTranslation();

	React.useEffect(() => {
		localStorage.setItem("locale", JSON.stringify("en"));
	}, []);

	return (
		<>
			<div className="homepage">
				<section className="homepage-one">
					<div className="one-content">
						<div className="one-box">
							<h1 className="talents-child-text">{t("homePage.talent_child")}</h1>
							<div className="talents-hr"></div>
							<p className="development-text">{t("homePage.development_and_educare")}</p>
							<a className="join-now-button">{t("homePage.join_now")}</a>
						</div>

						<div className="one-sns">
							<div className="one-sns-logo">
								<Image className="facebook-logo" src={facebookLogo} />
							</div>
							<div className="one-sns-descp">
								<p className="one-sns-find">{t("homePage.find_us_on_fb")}</p>
								<a className="one-sns-href" href="#">
									<p className="one-sns-txt">{t("homePage.development_educare")}</p>
								</a>
							</div>
						</div>
					</div>
				</section>

				<section className="homepage-two">
					<div className="two-content">
						<div className="two-box">
							<Row>
								<Col className="two-box-col" xs={12} sm={12} md={12} lg={7}>
									<h2 className="online-class-title">{t("homePage.online_class")}</h2>
									<p className="online-class-sub">{t("homePage.online_class_sub")}</p>
									<p className="online-class-desc">
										Our online classes are held on Zoom, with each teacher commanding a classroom of
										less than 10 students. The duration of online class is 1 hour and 30 minutes for
										each subject. Our teachers always try their hardest to liven up online classes,
										by getting the students to turn on their cameras. Look at them have fun!
									</p>
									<a href="#" className="how-it-works-container">
										<Image className="home-image-3" src={PlayLogo} />
										<p className="question-text">{t("homePage.how_it_works")} </p>
									</a>
								</Col>
								<Col className="two-box-col" xs={12} sm={12} md={12} lg={5}>
									<Image className="home-image-2" src={Home2} />
								</Col>
							</Row>
						</div>
						<div className="two-img">
							<Row>
								<Col xs={6} md={6} lg={4} xl={3}>
									<Image className="virtual-image" src={Virtual1} />
								</Col>

								<Col xs={6} md={6} lg={4} xl={3}>
									<Image className="virtual-image" src={Virtual2} />
								</Col>

								<Col xs={6} md={6} lg={4} xl={3}>
									<Image className="virtual-image" src={Virtual3} />
								</Col>

								<Col xs={6} md={6} lg={4} xl={3}>
									<Image className="virtual-image" src={Virtual4} />
								</Col>
							</Row>
						</div>
					</div>
				</section>

				<section className="homepage-three">
					<div className="three-content">
						<div className="courses-top">
							<Row className="courses-top-row">
								<Col md={9} xs={6} className="courses-top-col">
									<div className="course-txt-container">
										<h2 className="courses">{t("homePage.courses")}</h2>
										<p className="join-us">{t("homePage.join_us_now")}</p>
									</div>
								</Col>
								<Col md={3} xs={6} className="courses-top-col">
									<Image className="course-talents-logo" src={logo} />
								</Col>
							</Row>
						</div>
						<div className="courses-bottom">
							{coursesList.map((i, k) => {
								return (
									<div key={k} className="courses-list">
										<Row className="courses-list-row">
											<Col
												className="courses-list-col courses-list-img"
												lg="1"
												md="2"
												sm="12"
												xs="12"
											>
												<Image className="course-info-image" src={i.img} />
											</Col>
											<Col
												className="courses-list-col courses-list-descp"
												lg="11"
												md="10"
												sm="12"
												xs="12"
											>
												<div className="course-list-descp">
													<p className="course-info-subtitle">{i.title}</p>
													<p className="course-info-content">{i.descp}</p>
												</div>
											</Col>
										</Row>
									</div>
								);
							})}
						</div>
					</div>
				</section>

				<section className="homepage-four">
					<div className="four-content">
						<h2 className="increase-text">{t("homePage.increase_knowledge")}</h2>
						<p className="talents-development-text">{t("homePage.talent_development")}</p>
						<Image className="arrow-down" src={ArrowBlue} />
						<div className="carousel-container">
							<Carousel
								swipeable={false}
								draggable={false}
								responsive={responsive}
								autoPlay={true}
								infinite={true}
								autoPlaySpeed={5000}
								transitionDuration={500}
								itemClass="carousel-item-padding-40-px"
								containerClass="carousel-slider"
								arrows={false}
							>
								{carouselKnowledge.map((i, k) => {
									return <Image className="carousel-knowledge-img" src={i.img} key={k}></Image>;
								})}
							</Carousel>
						</div>
						<div className="gallery-container">
							<Image className="forward-image" src={Forward} />
							<p className="gallery-text">{t("homePage.go_to_gallery")}</p>
						</div>
					</div>
				</section>

				<section className="homepage-five">
					<div className="five-content">
						<h2 className="testimonials-title">{t("homePage.testimonials")}</h2>
						<div className="testimonials-slider">
							<PrimeCarousel
								value={carouselTestimonial}
								numVisible={3}
								numScroll={1}
								responsiveOptions={responsiveOptions}
								className="custom-carousel"
								circular={true}
								autoplayInterval={3000}
								itemTemplate={testimonialCard}
							/>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}
