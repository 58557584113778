const en = {
	translation: {
		//Sample Top Bar
		homePage: {
			talent_child: "Talent Child",
			development_and_educare: "Development and Educare",
			join_now: "JOIN NOW",
			find_us_on_fb: "Find Us On Facebook",
			development_educare: "Talents Child Development and Educare",
			online_class: "Online Class",
			online_class_sub: "Temporary – Only for the time being due to MCO",
			online_class_desc:
				"Our online classes are held on Zoom, with each teacher commanding a classroom of each subject. Our teachers always try their hardest to liven up online classes, by getting the students to turn on their cameras. Look at them have fun!",
			how_it_works: "How It Works",
			courses: "Courses",
			join_us_now: "Join Us Now",
			increase_knowledge: "Increase Your Child Knowledge",
			talent_development: "Talents Child Development",
			go_to_gallery: "Go To Gallery",
			testimonials: "Testimonials",
		},
		dayCare: {
			daycare: "Daycare",
			dev_and_edu: "Development and Educare",
			register_now: "Register Now",
			values: "Our Values",
			five_star: "5-Star Learning Environment",
			five_star_desc:
				"The center is fully equipped with a drama room, Smart TV Room, and 10 classrooms to ensure that children have ample space for activities.",
			registration: "One-click registration, trial lesson is arranged right away",
			registration_desc:
				"As long as the child is of suitable age, we will arrange a trial class for the chosen programme.",
			one_stop: "One-stop service",
			one_stop_desc:
				"We provide diversified courses. Children can learn all kinds of talents here. We are committed to cultivating children into all-round developed talents.",
			better_and_best: "Not only are we better, we strive for the best",
			better_and_best_desc:
				"The center adheres to the learning philosophy of 'teaching students to learn', and is committed to handing students learning methods and keys, inspiring children's thinking and cultivating their self-learning ability, so that they can 'explore the origin of knowledge and seek the destination of knowledge'. The learning ability acquired by the students here is more important than the learning knowledge itself. With this ability, even if they leave the teacher and the classroom, they can still learn by themselves.",
			faq: "Frequently asked questions",
			faq1: "Does Talents class provide additional tuition?",
			faq2: "My working hours are unstable, do you provide transport?",
			faq3: "At what age can my child be sent for classes?",
			faq4: "What is special about Talents' paintings?",
			faq5: "My child does not want to speak English at all. Can your Speech and Drama class help him?",
			faq6: "My child is 5 years old this year, but he can only read by heart and cannot read words. Is there any course to improve this situation?",
			faq7: "My child is unable to concentrate in his studies. What courses can help him to improve his concentration?",
			faq1_desc:
				"Talents not only focuses on helping the children complete the schoolwork of the day, we will also set aside an hour and a half to tutor the children.",
			faq2_desc:
				"We provide transportation services, as long as within a reasonable range, we can take your child home from the tuition center.",
			faq3_desc: "Children between the ages of 5 and 12 are welcome to enroll in our programmes.",
			faq4_desc:
				"Our paintings are based on creativity, integrating painting and handwork into children's paintings, and presenting the works through the children's imagination.",
			faq5_desc:
				"Absolutely achievable. Because our Speech and Drama course is held in a pleasant and relaxing environment, building up children’s confidence bit by bit, so as to break through the psychological barriers and gradually progress from learning.",
			faq6_desc:
				"He can enroll in the Chinese Reading Programme. Our Chinese character speed reading uses Chinese character cards and one-to-one teaching to ensure that children can learn, and that children can recognize characters instead of memorizing them.",
			faq7_desc:
				"Our mental arithmetic courses can definitely improve children's concentration and at the same time stimulate children's use of left and right brains. In the long run, children will be more focused on everything and their learning ability will also be improved.",
		},
		courses: {
			programs: "Programs",
			dev_and_edu: "Development and Educare",
			register_now: "Register Now",
			primary_tuition: "Primary School Tuition",
			primary_tuition_desc:
				"Your child will be on top of his or her schoolwork and never fall behind in class with this programme. Our teachers will guide them in completing their school",
			learn_more: "Learn More",
			ucmas: "UCMAS",
			ucmas_desc:
				"UCMAS is a unique and scientifically-proven mental development programme designed for children to develop their latent mental power at a very early age when brain development is at its peak. Try it and watch your child grow into a math genius!",
			robot: "World Robotics",
			robot_desc:
				"This programme helps kids facilitate science and math learning inside the classroom. Robotics connect science and math concepts",
			chinese_reading: "Chinese Reading Programme",
			chinese_reading_desc:
				"Our CRP is one of our most popular programmes for parents who wish for their children to have a good command of the Chinese language. We use flashcards because they help students to engage in active recall to remember a term, concept, or process without context clues. This is why students who join our CRP remembers a lot of Chinese characters effectively.",
			funart: "Funart",
			funart_desc:
				"As the name implies, FUN Arts is an arts and crafts programme that is interesting for children as our art teacher uses different sorts of mediums to teach children to create meaningful artworks. Come visit us at our centre and see for yourself! We proudly present the artworks there.",
			speech_and_drama: "Speech & Drama",
			speech_and_drama_desc:
				"Speech and Drama classes are a powerful way for children to learn the necessary building blocks for a life of effective communication. It empowers children and enables them to develop self-confidence, a creative imagination, and maturity in interpersonal skills. Their fluency in English will also be strengthened, and their vocabulary will be greatly improved. Watch your child blossom into a star after joining this!",
			educare: "Educare",
			dance: "Dance",
			martial_art: "Martial Art",
		},
		contact: {
			join_us: "Join Us",
			leave_your_info: "Leave your information as listed below, and we will arrange a trial class for you!",
			name: "Name",
			email: "Email",
			phone_number: "Phone Number",
			send: "Send",
		},
		nav: {
			contact_us: "Contact us at ",
			home: "Home",
			daycare: "Daycare",
			about_us: "About Us",
			courses: "Courses",
			photo: "Photo Gallery",
			contact: "Contact Us",
		},
		footer: {
			talent_child: "Talent Child",
			about: "About",
			event: "Event",
			courses: "Courses",
			gallery: "Gallery",
			howitworks: "How It Works",
			achievement: "Achievement",
			testimonial: "Testimonial",
			faqs: "FAQs",
			menu: "Menu",
			needhelp: "Need Help",
			emailus: "E-mail us",
			callus: "Call us",
			learn: "Learn",
		},
	},
};

export default en;
