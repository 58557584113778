const zh = {
	translation: {
		//Sample Top Bar
		homePage: {
            talent_child: "Talent Child",
			development_and_educare: "Development and Educare",
            join_now: "JOIN NOW",
            find_us_on_fb: "Find Us On Facebook",
            development_educare: "Talents Child Development and Educare",
            online_class: "线上教学",
            online_class_sub: "仅用于行动管制令期间",
            online_class_desc: "我们的在线课程在 Zoom 上进行，每位老师都指挥一个少于 10 名学生的教室。每个科目的在线课程时间为1小时30分钟。我们的老师总是尽最大努力让在线课程活跃起来，让学生们打开他们的相机。看他们学习得多开心！",
            how_it_works: "How It Works",
            courses: "我们的课程",
            join_us_now: "Join Us Now",
            increase_knowledge: "来天才教育心，让孩子“育”见未来",
            talent_development: "Talents Child Development",
            go_to_gallery: "Go To Gallery",
            testimonials: "看看家长们怎么说"
		},
        dayCare: {
            daycare: "Daycare",
            dev_and_edu: "Development and Educare",
            register_now: "马上报名",
            values: "我们这里有",
            five_star: "5星级的学习环境",
            five_star_desc: "本中心设备齐全，有话剧室，Smart TV  Room，和10间教室，保证孩子有足够的活动空间。",
            registration: "一键报名，马上试课",
            registration_desc: "只要孩子符合学习课程的年龄，我们便会安排孩子到相应的班级里试课.",
            one_stop: "一站式服务",
            one_stop_desc: "天才教育中心提供的课程多样化，孩子们可以在这里学习各式各样的才艺，我们致力于把孩子培养成全方位发展的人才。",
            better_and_best: "我们不只做得好，而是更好",
            better_and_best_desc: "本中心秉持着“教学生学”的学习理念，致力于交给学生学习方法和钥匙，启发孩子们的思维，培养他们的自学能力，才能“探知识的本源，求知识的归宿”。学生在这里获得的学习能力比学习知识本身更重要。有了这种能力，即使离开了老师，离开了课堂，他们照样能自律学习。",
            faq: "常见问题",
            faq1: "安亲班有提供额外的补习吗?",
            faq2: "我的孩子完全不想开口讲英文，你们的Speech and Drama 可以帮助他吗？",
            faq3: "我工作时间不稳定，孩子可以包载送吗？",
            faq4: "我的孩子今年5岁，可是他只能背读，不能认字，有什么课程可以改善这个情况吗？",
            faq5: "孩子多大可以送来安亲班？",
            faq6: "我的孩子在学习上不能专注，有什么课程可以使他提升专注力？",
            faq7: "Talents 的画画有什么特别？",
            faq1_desc: "Talents not only focuses on helping the children complete the schoolwork of the day, we will also set aside an hour and a half to tutor the children.",
            faq2_desc: "We provide transportation services, as long as within a reasonable range, we can take your child home from the tuition center.",
            faq3_desc: "Children between the ages of 5 and 12 are welcome to enroll in our programmes.",
            faq4_desc: "Our paintings are based on creativity, integrating painting and handwork into children's paintings, and presenting the works through the children's imagination.",
            faq5_desc: "Absolutely achievable. Because our Speech and Drama course is held in a pleasant and relaxing environment, building up children’s confidence bit by bit, so as to break through the psychological barriers and gradually progress from learning.",
            faq6_desc: "He can enroll in the Chinese Reading Programme. Our Chinese character speed reading uses Chinese character cards and one-to-one teaching to ensure that children can learn, and that children can recognize characters instead of memorizing them.",
            faq7_desc: "Our mental arithmetic courses can definitely improve children's concentration and at the same time stimulate children's use of left and right brains. In the long run, children will be more focused on everything and their learning ability will also be improved.",
        },
        courses: {
            programs: "Programs",
            dev_and_edu: "Development and Educare",
            register_now: "Register Now",
            primary_tuition: "Primary School Tuition",
            primary_tuition_desc: "Your child will be on top of his or her schoolwork and never fall behind in class with this programme. Our teachers will guide them in completing their school",
            learn_more: "Learn More",
            ucmas: "UCMAS",
            ucmas_desc: "UCMAS is a unique and scientifically-proven mental development programme designed for children to develop their latent mental power at a very early age when brain development is at its peak. Try it and watch your child grow into a math genius!",
            robot: "World Robotics",
            robot_desc: "This programme helps kids facilitate science and math learning inside the classroom. Robotics connect science and math concepts",
            chinese_reading: "Chinese Reading Programme",
            chinese_reading_desc: "Our CRP is one of our most popular programmes for parents who wish for their children to have a good command of the Chinese language. We use flashcards because they help students to engage in active recall to remember a term, concept, or process without context clues. This is why students who join our CRP remembers a lot of Chinese characters effectively.",
            funart: "Funart",
            funart_desc: "As the name implies, FUN Arts is an arts and crafts programme that is interesting for children as our art teacher uses different sorts of mediums to teach children to create meaningful artworks. Come visit us at our centre and see for yourself! We proudly present the artworks there.",
            speech_and_drama: "Speech & Drama",
            speech_and_drama_desc: "Speech and Drama classes are a powerful way for children to learn the necessary building blocks for a life of effective communication. It empowers children and enables them to develop self-confidence, a creative imagination, and maturity in interpersonal skills. Their fluency in English will also be strengthened, and their vocabulary will be greatly improved. Watch your child blossom into a star after joining this!",
            educare: "Educare",
            dance: "Dance",
            martial_art: "Martial Art"
        },
        contact: {
            join_us: "Join Us",
            leave_your_info: "留下你的资料吧，我们会为你的孩子安排试课",
            name: "姓名",
            email: "Email",
            phone_number: "电话号码",
            send: "Send"
        },
        nav: {
            contact_us: "Contact us at ",
            home: "主页",
            daycare: "安亲班",
            about_us: "关于我们",
            courses: "课程",
            photo: "相册",
            contact: "联系我们吧"
        }
	},
};

export default zh;
