import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Drawer from "@material-ui/core/Drawer";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TiBackspaceOutline } from "react-icons/ti";
import { useLocation } from "react-router-dom";
import "../styles/components/Drawer.scss";

function MobileDrawer({ status, setDrawerStatus }) {
	const { t } = useTranslation();
	let location = useLocation();
	const initialRender = React.useRef(true);

	React.useEffect(() => {
		if (initialRender.current) {
			initialRender.current = false;
		} else {
			setDrawerStatus();
		}
	}, [location]);

	return (
		<Drawer anchor={"left"} open={status} onClose={() => setDrawerStatus()} className="mobile-drawer">
			<List>
				<ListItem button>
					<ListItemText>
						<div className="main-title">
							<h3>Quick Access</h3>
							<a onClick={() => setDrawerStatus()}>
								<TiBackspaceOutline />
							</a>
						</div>
					</ListItemText>
				</ListItem>
				<ListItem button>
					<ListItemText>
						<NavLink exact className="nav-title" to={`/`}>
							<p className="nav-txt">{t("nav.home")}</p>
						</NavLink>
					</ListItemText>
				</ListItem>
				<ListItem button>
					<ListItemText>
						<NavLink exact className="nav-title" to={`/daycare`}>
							<p className="nav-txt">{t("nav.daycare")}</p>
						</NavLink>
					</ListItemText>
				</ListItem>
				<ListItem button>
					<ListItemText>
						<NavLink exact className="nav-title" to={`/courses`}>
							<p className="nav-txt">{t("nav.courses")}</p>
						</NavLink>
					</ListItemText>
				</ListItem>
				<ListItem button>
					<ListItemText>
						<NavLink exact className="nav-title" to={`/photo`}>
							<p className="nav-txt">{t("nav.photo")}</p>
						</NavLink>
					</ListItemText>
				</ListItem>
				<ListItem button>
					<ListItemText>
						<NavLink exact className="nav-title" to={`/about`}>
							<p className="nav-txt">{t("nav.about_us")}</p>
						</NavLink>
					</ListItemText>
				</ListItem>
				<ListItem button>
					<ListItemText>
						<NavLink exact className="nav-title" to={`/contact`}>
							<p className="nav-txt">{t("nav.contact")}</p>
						</NavLink>
					</ListItemText>
				</ListItem>
			</List>
		</Drawer>
	);
}

export default MobileDrawer;
