import React from "react";
import { NavLink, Link } from "react-router-dom";
import "../styles/components/NavBar.scss";
import { Row, Col, Image } from "react-bootstrap";
import logo from "../assets/images/official/common/Talent web-07.png";
import { useTranslation } from "react-i18next";
import "../styles/components/Responsive/NavBar.scss";

function NavBar() {
	const { t } = useTranslation();

	return (
		<>
			<div className="topbar">
				<div className="top-contact">
					<p className="contact-txt">{t("nav.contact_us")} 03-6138 7723 / 03-6125 2722 / 012-299 7723</p>
				</div>
				<div className="top-menus">
					<div className="top-menu">
						<Row>
							<Col className="menu-col" md={4}>
								<div className="nav-circle-icon nav-icon-large left"></div>
								<div className="nav-circle-icon nav-icon-small leftleft"></div>
								<Row className="nav-bar-row">
									<Col>
										<NavLink exact className="nav-title" to={`/`}>
											<div className="nav-title-txt">
												<span className="nav-txt">{t("nav.home")}</span>
												<div className="nav-hr"></div>
												<div className="nav-circle"></div>
											</div>
										</NavLink>
									</Col>
									<Col>
										<NavLink exact className="nav-title" to={`/daycare`}>
											<div className="nav-title-txt">
												<span className="nav-txt">{t("nav.daycare")}</span>
												<div className="nav-hr"></div>
												<div className="nav-circle"></div>
											</div>
										</NavLink>
									</Col>
									<Col>
										<NavLink exact className="nav-title" to={`/courses`}>
											<div className="nav-title-txt">
												<span className="nav-txt">{t("nav.courses")}</span>
												<div className="nav-hr"></div>
												<div className="nav-circle"></div>
											</div>
										</NavLink>
									</Col>
								</Row>
							</Col>
							<Col className="menu-col" md={4}>
								<Row className="image-col">
									<Col>
										<Link className="nav-img" to={`/`}>
											<Image className="top-talents-logo" src={logo} />
										</Link>
									</Col>
								</Row>
							</Col>
							<Col className="menu-col" md={4}>
								<Row className="nav-bar-row">
									<Col>
										<NavLink exact className="nav-title" to={`/photo`}>
											<div className="nav-title-txt">
												<span className="nav-txt">{t("nav.photo")}</span>
												<div className="nav-hr"></div>
												<div className="nav-circle"></div>
											</div>
										</NavLink>
									</Col>
									<Col>
										<NavLink exact className="nav-title" to={`/about`}>
											<div className="nav-title-txt">
												<span className="nav-txt">{t("nav.about_us")}</span>
												<div className="nav-hr"></div>
												<div className="nav-circle"></div>
											</div>
										</NavLink>
									</Col>
									<Col>
										<NavLink exact className="nav-title" to={`/contact`}>
											<div className="nav-title-txt">
												<span className="nav-txt">{t("nav.contact")}</span>
												<div className="nav-hr"></div>
												<div className="nav-circle"></div>
											</div>
										</NavLink>
									</Col>
									<div className="nav-circle-icon nav-icon-large right"></div>
									<div className="nav-circle-icon nav-icon-small rightright"></div>
								</Row>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</>
	);
}

export default NavBar;
