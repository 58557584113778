import Home from "./views/Home";
import DayCare from "./views/DayCare";
import Courses from "./views/Courses";

export const Website = [
	{
		path: "/daycare",
		layout: "website",
		component: DayCare,
	},
	{
		path: "/courses",
		layout: "website",
		component: Courses,
	},
	{
		path: "/",
		layout: "website", //standby
		component: Home,
	},
];
