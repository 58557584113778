import React from "react";
import { Col, Row } from "react-bootstrap";
import "../styles/pages/Daycare.scss";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import "../styles/pages/Responsive/Daycare.scss";

import daycare2 from "../assets/images/official/daycare/Talent web-10.png";
import daycare3 from "../assets/images/official/daycare/Talent web-11.png";
import daycare4 from "../assets/images/official/daycare/Talent web-12.png";

export default function DayCare() {
	const [accordionOne, setAccordionOne] = React.useState(false);
	const [accordionTwo, setAccordionTwo] = React.useState(false);
	const [accordionThree, setAccordionThree] = React.useState(false);

	const { t } = useTranslation();

	return (
		<>
			<div className="daycare">
				<section className="area-one">
					<div className="one-data">
						<h1 className="one-data-title">{t("dayCare.daycare")}</h1>
						<div className="one-data-hr"></div>
						<p className="one-data-subtitle">{t("dayCare.dev_and_edu")}</p>
						<a className="one-data-link">Register Now</a>
					</div>
				</section>
				<section className="area-two">
					<div className="two-data">
						<h3 className="two-data-title">{t("dayCare.values")}</h3>
						<Row className="two-row">
							<Col lg={4} md={4} className="two-data-col">
								<img className="two-data-img" src={daycare2}></img>
								<p className="two-data-subtitle">{t("dayCare.five_star")}</p>
								<p className="two-data-descp">
                                    {t("dayCare.five_star_desc")}
								</p>
							</Col>
							<Col lg={4} md={4} className="two-data-col">
								<img className="two-data-img" src={daycare3}></img>
								<p className="two-data-subtitle">
                                    {t("dayCare.registration")}
								</p>
								<p className="two-data-descp">
                                    {t("dayCare.registration_desc")}
								</p>
							</Col>
							<Col lg={4} md={4} className="two-data-col">
								<img className="two-data-img" src={daycare4}></img>
								<p className="two-data-subtitle">{t("dayCare.one_stop")}</p>
								<p className="two-data-descp">
                                    {t("dayCare.one_stop_desc")}
								</p>
							</Col>
						</Row>
					</div>
				</section>
				<section className="area-three">
					<h3 className="two-data-title">{t("dayCare.better_and_best")}</h3>
					<p className="two-data-descp">
						{t("dayCare.better_and_best_desc")}
					</p>
				</section>
				<section className="area-four">
					<h3 className="four-data-title">{t("dayCare.faq")}</h3>
					<Row className="four-data">
						<Col lg={6} className="four-col">
							<div className="accordion-box">
								<Accordion onChange={() => setAccordionOne(!accordionOne)}>
									<AccordionSummary expandIcon={accordionOne ? <RemoveIcon /> : <AddIcon />}>
										<p className="four-accordion-title">
											{t("dayCare.faq1")}
										</p>
									</AccordionSummary>
									<AccordionDetails>
										<p className="four-accordion-descp">
                                            {t("dayCare.faq1_desc")}
										</p>
									</AccordionDetails>
								</Accordion>
							</div>
						</Col>
						<Col lg={6} className="four-col">
							<div className="accordion-box">
								<Accordion onChange={() => setAccordionTwo(!accordionTwo)}>
									<AccordionSummary expandIcon={accordionTwo ? <RemoveIcon /> : <AddIcon />}>
										<p className="four-accordion-title">
                                            {t("dayCare.faq2")}
										</p>
									</AccordionSummary>
									<AccordionDetails>
										<p className="four-accordion-descp">
                                            {t("dayCare.faq2_desc")}
										</p>
									</AccordionDetails>
								</Accordion>
							</div>
						</Col>
						<Col lg={6} className="four-col">
							<div className="accordion-box">
								<Accordion onChange={() => setAccordionThree(!accordionThree)}>
									<AccordionSummary expandIcon={accordionThree ? <RemoveIcon /> : <AddIcon />}>
										<p className="four-accordion-title">
                                            {t("dayCare.faq3")}
										</p>
									</AccordionSummary>
									<AccordionDetails>
										<p className="four-accordion-descp">
                                            {t("dayCare.faq3_desc")}
										</p>
									</AccordionDetails>
								</Accordion>
							</div>
						</Col>
						<Col lg={6} className="four-col">
							<div className="accordion-box">
								<Accordion onChange={() => setAccordionThree(!accordionThree)}>
									<AccordionSummary expandIcon={accordionThree ? <RemoveIcon /> : <AddIcon />}>
										<p className="four-accordion-title">
                                            {t("dayCare.faq4")}
										</p>
									</AccordionSummary>
									<AccordionDetails>
										<p className="four-accordion-descp">
                                            {t("dayCare.faq4_desc")}
										</p>
									</AccordionDetails>
								</Accordion>
							</div>
						</Col>
						<Col lg={6} className="four-col">
							<div className="accordion-box">
								<Accordion onChange={() => setAccordionThree(!accordionThree)}>
									<AccordionSummary expandIcon={accordionThree ? <RemoveIcon /> : <AddIcon />}>
										<p className="four-accordion-title">
                                            {t("dayCare.faq5")}
										</p>
									</AccordionSummary>
									<AccordionDetails>
										<p className="four-accordion-descp">
                                            {t("dayCare.faq5_desc")}
										</p>
									</AccordionDetails>
								</Accordion>
							</div>
						</Col>
						<Col lg={6} className="four-col">
							<div className="accordion-box">
								<Accordion onChange={() => setAccordionThree(!accordionThree)}>
									<AccordionSummary expandIcon={accordionThree ? <RemoveIcon /> : <AddIcon />}>
										<p className="four-accordion-title">
                                            {t("dayCare.faq6")}
										</p>
									</AccordionSummary>
									<AccordionDetails>
										<p className="four-accordion-descp">
                                            {t("dayCare.faq6_desc")}
										</p>
									</AccordionDetails>
								</Accordion>
							</div>
						</Col>
						<Col lg={6} className="four-col">
							<div className="accordion-box">
								<Accordion onChange={() => setAccordionThree(!accordionThree)}>
									<AccordionSummary expandIcon={accordionThree ? <RemoveIcon /> : <AddIcon />}>
										<p className="four-accordion-title">
                                            {t("dayCare.faq7")}
										</p>
									</AccordionSummary>
									<AccordionDetails>
										<p className="four-accordion-descp">
                                            {t("dayCare.faq7_desc")}
										</p>
									</AccordionDetails>
								</Accordion>
							</div>
						</Col>
					</Row>
				</section>
			</div>
		</>
	);
}
