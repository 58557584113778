import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NameIcon from "../assets/images/official/common/Talent web-50.png";
import EmailIcon from "../assets/images/official/common/Talent web-51.png";
import PhoneIcon from "../assets/images/official/common/Talent web-52.png";
import "../styles/components/Contact.scss";
import "../styles/components/Responsive/Contact.scss";

const Contact = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className="join-advertisement">
				<div className="join-us-wrapper">
					<h2 className="join-us-title">{t("contact.join_us")}</h2>
					<p className="join-us-desc">
						Leave your information as listed below, and we will arrange a trial class for you!
					</p>
					<div className="join-us-form">
						<Row className="join-us-row">
							<Col className="join-us-col" md="6" xs="12">
								<div className="join-us-inputs">
									<img className="join-us-icon" src={NameIcon} alt="name" />
									<input className="join-us-input" type="text" placeholder={t("contact.name")} />
								</div>
							</Col>
							<Col className="join-us-col" md="6" xs="12">
								<div className="join-us-inputs">
									<img className="join-us-icon join-email" src={EmailIcon} alt="email" />
									<input className="join-us-input" type="text" placeholder={t("contact.email")} />
								</div>
							</Col>
							<Col className="join-us-col" md="12" xs="12">
								<div className="join-us-inputs">
									<img className="join-us-icon" src={PhoneIcon} alt="phone number" />
									<input className="join-us-input" type="text" placeholder={t("contact.phone_number")} />
								</div>
							</Col>
						</Row>
						<button className="send-button" variant="primary" type="submit">
							{t("contact.send")}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default Contact;
